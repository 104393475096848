<section class="daken-section">
    <div class="heading-box">
        <h3>Daken</h3>
        <div class="underline"></div>
        <p class="intro-text">Zit u met een lek of is uw dak dringend aan vervanging toe. Heeft u een puntdak of een
            platte dak. Wij zorgen ervoor dat uw dak weer snel zal stralen. Wij zijn zowel gespecialiseerd in het
            aanleggen van roofing, leien (wij leggen ook leien aan gevels) als zink en koper.</p>
    </div>

    <div class="grid-4">
        <div class="intro-text-column">
            <h3>Ontdek hier enkele van onze dakwerken.</h3>            
        </div>
        <div class="image-bg _1"></div>
        <div class="image-bg _2"></div>
        <div class="image-bg _3"></div>
    </div>
</section>
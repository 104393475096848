import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-last-work-section',
  templateUrl: './last-work-section.component.html',
  styleUrls: ['./last-work-section.component.scss']
})
export class LastWorkSectionComponent implements OnInit {
  images: string[];
  currentImageId: number = 0;

  constructor(private http: HttpClient) {
    this.getJSON().subscribe(data => {
      this.images = data.map(r => r.path);
    });
  }

  public getJSON(): Observable<any> {
    return this.http.get("../../assets/projects.json");
  }

  ngOnInit(): void {
  }

  previousImage(): void {
    if (this.currentImageId === 0) {
      this.currentImageId = this.images.length - 1;
    } else {
      this.currentImageId--;
    }
  }

  nextImage(): void {
    if (this.currentImageId === this.images.length - 1) {
      this.currentImageId = 0;
    } else {
      this.currentImageId++;
    }
  }
}

<section class="dakkapel-velux-section">
    <div class="heading-box">
        <h3>Dakkapellen en velux ramen</h3>
        <div class="underline"></div>
    </div>

    <div class="grid-2">
        <div class="dakkapel-text">
            <h3>Is het nu om extra ruimte te creëren of uw zolder wat op te frissen. Een dakkapel zorgt voor een mooie
                frisse look, zowel binnenshuis als buitenshuis. Dat extra ruimte die je erbij wint, is altijd goed
                meegenomen.</h3>
        </div>
        <div class="image-bg _1"></div>
    </div>

    <br /><br />
    
    <div class="grid-3">
        <div class="image-bg _2"></div>
        <div class="image-bg _3"></div>
        <div class="velux-text">
            <h3>Wat dacht u ervan om een velux raam te plaatsen?<br /> Ook hierbij kunnen wij u helpen.</h3>
        </div>
    </div>
</section>
<app-hero-section></app-hero-section>

<app-last-work-section></app-last-work-section>

<app-daken-section></app-daken-section>

<app-goten-section></app-goten-section>

<app-gevel-bekleding-section></app-gevel-bekleding-section>

<app-dakkapel-velux-section></app-dakkapel-velux-section>

<app-isolatie-section></app-isolatie-section>

<app-reparatie-section></app-reparatie-section>

<app-footer></app-footer>
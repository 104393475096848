<section class="hero-section wf-section">
    <div class="container">
        <img src="../../../assets/logo.png" alt="choudak" class="logo">
        <h1>Algemene dakwerken en renovaties</h1>
        <p class="tagline">Uw huis is ongetwijfeld uw belangrijkste investering en een ultieme <strong>(chou)dak</strong> is de beste
            manier om het te beschermen.
            Wij bij <strong>choudak</strong> nemen dit heel serieus.</p>
        <br><br>
        <p class="gevelbekleding">WIJ BIEDEN NU OOK <strong>GEVELBEKLEDING</strong> AAN.</p>
    </div>
    <img src="../../../assets/bestelwagen.jpg" loading="lazy" alt="choudak" class="hero-section-img">
</section>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reparatie-section',
  templateUrl: './reparatie-section.component.html',
  styleUrls: ['./reparatie-section.component.scss']
})
export class ReparatieSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

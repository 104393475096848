<section class="goten-section">
    <div class="heading-box">
        <h3>Goten</h3>
        <div class="underline"></div>
        <p class="intro-text">Naast het herstellen van daken, springen wij ook in als het aankomt in het aanleggen of
            herstellen van dakgoten: traditionele dakgoten of zinkgoten.</p>
    </div>

    <div class="grid-4">
        <div class="image-bg _1"></div>
        <div class="image-bg _2"></div>
        <div class="image-bg _3"></div>
        <div class="intro-text-column">
            <h3>Enkele voorbeelden van goten die we aanleggen.</h3>
        </div>
    </div>
</section>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gevel-bekleding-section',
  templateUrl: './gevel-bekleding-section.component.html',
  styleUrls: ['./gevel-bekleding-section.component.scss']
})
export class GevelBekledingSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

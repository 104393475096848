<section class="last-work-section">
    <h2>Een glimps van wat wij doen</h2>

    <div class="image-container">
        <div class="image-layer">
            <img *ngIf="images" src={{images[currentImageId]}} alt="">
            <div class="previous">
                <div class="icon-left" (click)="previousImage()"></div>
            </div>
            <div class="next">
                <div class="icon-right" (click)="nextImage()"></div>
            </div>
        </div>
    </div>
</section>
<section class="footer">
    <div class="inner">
        <h2 class="major">Kom in contact</h2>
        <br>
        <p>
            Wilt u wat meer informatie over ons bekomen of hebt u wat meer uitleg nodig. Dan kan u ons altijd telefonisch bereiken op onderstaande telefoonnummer.
        </p>
        <ul class="contact">
            <li>
                <img src="../../../assets/icons/phone.svg" alt="">
                0472 115 454
            </li>
            <li class="icon">
                <img src="../../../assets/icons/envelope.svg" alt="">info@choudak.com
            </li>
        </ul>
    </div>
</section>
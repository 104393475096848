<section class="isolatie-section">
    <div class="grid-2">
        <div class="images">
            <div class="image-group">
                <img src="../../../assets/isolatie/isolatie1.jpeg" alt="">
                <img src="../../../assets/isolatie/isolatie2.jpeg" alt="">
            </div>
            <div class="image-group image-group-descended">
                <img src="../../../assets/isolatie/isolatie3.jpeg" alt="">
                <img src="../../../assets/isolatie/isolatie4.jpeg" alt="">
            </div>
            <div class="image-group">
                <img src="../../../assets/isolatie/isolatie5.jpeg" alt="">
                <img src="../../../assets/isolatie/isolatie6.jpeg" alt="">
            </div>
        </div>
        <div class="content">
            <h3>Isolatie</h3>
            <div class="underline"></div>
            <p>
                Wist u dat u heel wat euro’s op jaarlijkse basis kan besparen met een degelijke dakisolatie. Wij bieden
                u daarom deze vormen van isolatie aan.
            </p>

            <ul>
                <li>iko enertherm</li>
                <li>epdm dakbedekking</li>
            </ul>
        </div>
    </div>
</section>
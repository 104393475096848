import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeroSectionComponent } from './components/hero-section/hero-section.component';
import { FooterComponent } from './components/footer/footer.component';
import { DakenSectionComponent } from './components/daken-section/daken-section.component';
import { GotenSectionComponent } from './components/goten-section/goten-section.component';
import { GevelBekledingSectionComponent } from './components/gevel-bekleding-section/gevel-bekleding-section.component';
import { DakkapelVeluxSectionComponent } from './components/dakkapel-velux-section/dakkapel-velux-section.component';
import { IsolatieSectionComponent } from './components/isolatie-section/isolatie-section.component';
import { ReparatieSectionComponent } from './components/reparatie-section/reparatie-section.component';
import { LastWorkSectionComponent } from './components/last-work-section/last-work-section.component';

@NgModule({
  declarations: [
    AppComponent,
    HeroSectionComponent,
    FooterComponent,
    DakenSectionComponent,
    GotenSectionComponent,
    GevelBekledingSectionComponent,
    DakkapelVeluxSectionComponent,
    IsolatieSectionComponent,
    ReparatieSectionComponent,
    LastWorkSectionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

<section class="gevelbekleding-section">
    <div class="container">
        <div class="image-container">
            <img src="../../../assets/gevel/gevel.jpeg" alt="Choudak gevel gevelbekleding">
            <div class="black-line-top-left-1"></div>
            <div class="black-line-top-left-2"></div>
            <div class="black-line-bottom-right-1"></div>
            <div class="black-line-bottom-right-2"></div>
        </div>
        <div class="description-wrapper">
            <div class="description-container">
                <h2>Nu ook gevelbekleding</h2>
                <div class="underline"></div>
                <span>Wij zorgen voor die frisse look van uw voorgevel. Geef ons een belletje en we komen graag langs om
                    eens samen te zitten. De oplossingen die we aanbieden zijn uiteenlopend.</span>
            </div>
        </div>
    </div>
</section>